import { createApp } from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import InlineSvg from 'vue-inline-svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import focusvisible from 'focus-visible';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import MedNytt from './MedNytt.vue';
import EpiEdit from '../directives/epiEdit';
import router from '../router';
import store from '../store';
import '../epiBootstrap';
import '../assets/styles/main.css';
import i18n from '../localization';

const app = createApp(MedNytt)
  .directive('epi-edit', EpiEdit)
  .use(store)
  .use(router)
  .use(focusvisible)
  .use(i18n)
  .use(VueReCaptcha, {
    siteKey: '6Lcec8oZAAAAAMs-v_3gKha40iOT6VTiinMzjDLe',
  })
  .use(VueToast, {
    position: 'bottom-right',
    duration: 3000,
  });

// Register all Episerver view components globally. This requires webpack!
// Otherwise we need to register all components manually here in main.js.
const requireComponent = require.context('../views', true, /.vue$/);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  // Gets the component name regardless folder depth
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '');

  // Look for the component options on `.default`, which will
  // exist if the component was exported with `export default`,
  // otherwise fall back to module's root.
  app.component(componentName, componentConfig.default || componentConfig);
});

global.router = router;

app.config.globalProperties.$uuid = uuidv4;

// SVG Inline
app.component('inline-svg', InlineSvg);

// Fontawesome
library.add(fas);
library.add(fab);
app.config.productionTip = false;
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
