<template>
  <div v-if="modelLoaded" class="bg-white">
    <Shortcuts />
    <Header :model="model" :searchLabel="$t('search.searchBar.label.notification')"/>
    <Loading v-if="!modelLoaded" />
    <RouterView id="innhold"/>
    <Footer v-epi-edit="'Footer'" :footer="model.footer" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { defaultConfig } from '@episerver/content-delivery';
  import Footer from '@/views/components/footer/Footer.vue';
  import Header from '@/views/components/header/Header.vue';
  import Loading from '@/views/components/Loading.vue';
  import Shortcuts from '@/views/components/shortcuts/Shortcuts.vue';

  export default {
    components: {
      Footer,
      Header,
      Loading,
      Shortcuts,
    },
    computed: mapState({
      model: (state) => state.epiDataModel.model,
      modelLoaded: (state) => state.epiDataModel.modelLoaded,
    }),
    beforeMount() {
      this.$store.dispatch('setSearchRoute', 'methodnotifications');
    },
    mounted() {
      if (typeof process.env.VUE_APP_CONTENT_DELIVERY_API_MEDNYTT === 'undefined') {
        console.warn('Vue app content delivery API is missing. Check .env-file');
      }

      defaultConfig.apiUrl = `${process.env.VUE_APP_CONTENT_DELIVERY_API_MEDNYTT}/api/episerver/v3.0`;

      // defaultConfig.getAccessToken = () => authService.getAccessToken();
      defaultConfig.selectAllProperties = true;
      defaultConfig.expandAllProperties = true;

      /*
       * If the `epieditmode` parameter is present we know we're
       * in either edit- or preview mode and should include following scripts.
       */
      if (this.$route.query.epieditmode || document.location.search.includes('epieditmode')) {
        // const domainScript = document.createElement('script');
        // domainScript.innerHTML = 'document.domain = \'localhost\';';
        // document.body.appendChild(domainScript);

        const communicationScript = document.createElement('script');
        /* communicationScript.src = `${process.env.VUE_APP_CONTENT_DELIVERY_API_MEDNYTT}/episerver/cms/12.1.0/clientresources/epi-cms/communicationinjector.js`; */
        communicationScript.src = `${process.env.VUE_APP_CONTENT_DELIVERY_API_MEDNYTT}/episerver/cms/latest/clientresources/epi-cms/communicationinjector.js`;
        document.body.appendChild(communicationScript);
      }
    },
    beforeUpdate() {
      if (this.modelLoaded) {
        this.$root.$i18n.locale = this.model.language.name;
      }
    },
  };
</script>
